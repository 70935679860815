import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['self']
    static values = { togglePath: String }

    connect() {
        console.log('Services#connect')
    }

    toggleService(event) {
        console.log(`Path: ${this.togglePathValue}`)
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.togglePathValue,
            success: function(response) {
                console.log('We got something')
                that.selfTarget.outerHTML = response.html
            },
            error: function(response) {

            }
        })
    }
}