import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="help-notifications"
export default class extends Controller {
  static values = { ident:String, title:String, body:String, hide:String, placement:String }

  connect() {
    // Find element with ID
    this._element = document.getElementById(this.identValue);

    // Add in attributes
    this._element.setAttribute('data-bs-container', "body")
    this._element.setAttribute('data-bs-placement', this.placementValue)
    this._element.setAttribute('data-bs-title', this.titleValue)
    this._element.setAttribute('data-bs-content', this.bodyValue)

    this.popover = new bootstrap.Popover(this._element, {
      keyboard: false,
      html: true
    })
    this.popover.show()

    this._container = document.getElementById(`container-${this.identValue}`);
    this._container.setAttribute('data-controller', "help-popover")
    this._container.setAttribute('data-help-popover-ident-value', this.identValue)
    this._container.setAttribute('data-help-popover-hide-value', this.hideValue)

    this._hide = document.getElementById(`hide-${this.identValue}`);
    this._hide.setAttribute('data-action', "click->help-popover#hide")

  }
}
