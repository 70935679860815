import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    console.log('Popover#connect')

    this.popover = new bootstrap.Popover(this.element, {
      keyboard: false
    })
    this.popover.enable()
  }

}